import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faMinusSquare } from '@fortawesome/free-solid-svg-icons';

const CancelSubscriptionInstruction = ({ platform }) => {

  const { localization } = useSelector(state => state.app.watch);

  const [isOpen, setIsOpen] = useState(false);
  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };
  const defaultInstructions = {
    Android: `<p data-renderer-start-pos="180"><span style="font-family: tahoma, arial, helvetica, sans-serif;"><strong><span>Looks like your subscription was purchased on an Android device. Please review the following:</span></strong></span></p>
              <p data-renderer-start-pos="520"><span style="font-family: tahoma, arial, helvetica, sans-serif;"><strong>Cancel on the Google Play Store app:</strong></span></p>
              <ol class="ak-ol" data-indent-level="1">
              <li><p data-renderer-start-pos="560"><span style="font-family: tahoma, arial, helvetica, sans-serif;">On your Android phone or tablet, open the Google Play Store app.</span></p></li>
              <li><p data-renderer-start-pos="624"><span style="font-family: tahoma, arial, helvetica, sans-serif;">Make sure you're signed in to the correct Google Account. To check that, tap the profile icon at the top right.</span></p></li>
              <li><p data-renderer-start-pos="694"><span style="font-family: tahoma, arial, helvetica, sans-serif;">Tap Menu > Payments & subscriptions > Subscriptions.</span></p></li>
              <li><p data-renderer-start-pos="723"><span style="font-family: tahoma, arial, helvetica, sans-serif;">Select the subscription you want to cancel.</span></p></li>
              <li><p data-renderer-start-pos="770"><span style="font-family: tahoma, arial, helvetica, sans-serif;">Tap Cancel subscription.</span></p></li>
              <li><p data-renderer-start-pos="798"><span style="font-family: tahoma, arial, helvetica, sans-serif;">Follow the instructions provided.</span></p></li>
              </ol>
              <p data-renderer-start-pos="180"> <span style="font-family: tahoma, arial, helvetica, sans-serif;"><strong>Cancel on the Web Browser:</strong></span> </p>
              <ol class="ak-ol" data-indent-level="1">
              <li><p data-renderer-start-pos="560"><span style="font-family: tahoma, arial, helvetica, sans-serif;">On your browser go to <strong>g.co/Play/Subscriptions</strong> and login with your google account which you used to purchase the subscription.</span></p></li>
              <li><p data-renderer-start-pos="624"><span style="font-family: tahoma, arial, helvetica, sans-serif;">Make sure you're signed in to the correct Google Account. To check that, tap the profile icon at the top right.</span></p></li>
              <li><p data-renderer-start-pos="694"><span style="font-family: tahoma, arial, helvetica, sans-serif;">Tap Menu > Payments & subscriptions > Subscriptions.</span></p></li>
              <li><p data-renderer-start-pos="723"><span style="font-family: tahoma, arial, helvetica, sans-serif;">Select the subscription you want to cancel.</span></p></li>
              <li><p data-renderer-start-pos="770"><span style="font-family: tahoma, arial, helvetica, sans-serif;">Tap Cancel subscription.</span></p></li>
              <li><p data-renderer-start-pos="798"><span style="font-family: tahoma, arial, helvetica, sans-serif;">Follow the instructions provided.</span></p></li>
              </ol>`,
    Web: '<p data-renderer-start-pos="180"><span style="font-family: tahoma, arial, helvetica, sans-serif;"><strong>Looks like your subscription was purchased on Web. Please review the following:</strong></span></p>\n<p data-renderer-start-pos="180"> </p>\n<p data-renderer-start-pos="180"><strong>Steps to cancel your subscription:</strong></p>\n<ol class="ak-ol" data-indent-level="1">\n<li>\n<p data-renderer-start-pos="218">Click on Account & Settings (account icon).</p>\n</li>\n<li>\n<p data-renderer-start-pos="265">Click on Manage Subscription.</p>\n</li>\n<li>\n<p data-renderer-start-pos="298">Click on Change Plan.</p>\n</li>\n<li>\n<p data-renderer-start-pos="323">Click on Cancel Subscription option.</p>\n</li>\n</ol>',
    Apple: '<p data-renderer-start-pos="180"><strong>Looks like your subscription was purchased on iPhone/iPad. Please review the following:</strong></p>\n<p data-renderer-start-pos="180"><strong>Steps to cancel your subscription on iPhone or iPad:</strong></p>\n<ol class="ak-ol" data-indent-level="1">\n<li>\n<p data-renderer-start-pos="218">Open the Settings app on iPhone or iPad.</p>\n</li>\n<li>\n<p data-renderer-start-pos="244">Tap your name (YES Network).</p>\n</li>\n<li>\n<p data-renderer-start-pos="276">Tap Subscriptions.</p>\n</li>\n<li>\n<p data-renderer-start-pos="298">Tap the subscription.</p>\n</li>\n<li>\n<p data-renderer-start-pos="323">Tap Cancel Subscription. You might need to scroll down to find the Cancel Subscription button. If there is no Cancel button or you see an expiration message in red text, the subscription is already canceled.</p>\n</li>\n</ol>\n<p data-renderer-start-pos="180"><strong>Steps to cancel your subscription on Apple TV:</strong></p>\n<ol class="ak-ol" data-indent-level="1">\n<li>\n<p data-renderer-start-pos="308">Open Settings on Apple TV.</p>\n</li>\n<li>\n<p data-renderer-start-pos="338">Go to Users and Accounts > [<em data-renderer-mark="true">account name</em>] Subscriptions and select a subscription (select the subscription for YES Network).</p>\n</li>\n<li>\n<p data-renderer-start-pos="466">Follow the onscreen instructions to change or cancel your subscription.</p>\n</li>\n</ol>',
    FireTV: '<p data-renderer-start-pos="180"><span style="font-family: tahoma, arial, helvetica, sans-serif;"><strong>Looks like your subscription was purchased on Fire TV. Please review the following:</strong></span></p>\n<p data-renderer-start-pos="272"><strong data-renderer-mark="true">Cancel subscription instructions</strong></p>\n<ol class="ak-ol" data-indent-level="1">\n<li>\n<p data-renderer-start-pos="308">Sign in to Amazon.</p>\n</li>\n<li>\n<p data-renderer-start-pos="330">Go to Your Account.</p>\n</li>\n<li>\n<p data-renderer-start-pos="353">Select Memberships & Subscriptions.</p>\n</li>\n<li>\n<p data-renderer-start-pos="392">Find the subscription you want to manage and select Actions.</p>\n</li>\n<li>\n<p data-renderer-start-pos="456">Choose from available options: Cancel your subscription</p>\n</li>\n</ol>',
    WebSplitit: '<p data-renderer-start-pos="180"><span style="font-family: tahoma, arial, helvetica, sans-serif;"><strong>Looks like your subscription was purchased on Web. Please review the following:</strong></span></p>\n<p data-renderer-start-pos="180"> </p>\n<p data-renderer-start-pos="180"><strong>Steps to cancel your subscription:</strong></p>\n<ol class="ak-ol" data-indent-level="1">\n<li>\n<p data-renderer-start-pos="218">Click on Account & Settings (account icon).</p>\n</li>\n<li>\n<p data-renderer-start-pos="265">Click on Manage Subscription.</p>\n</li>\n<li>\n<p data-renderer-start-pos="323">Click on Cancel Subscription option.</p>\n</li>\n</ol>',
    Roku: '<p data-renderer-start-pos="180"><strong>Looks like your subscription was purchased on Roku. Please review the following:</strong></p>\n\n<p data-renderer-start-pos="272"><strong data-renderer-mark="true">Cancel subscription instructions</strong></p>\n<p><strong>From a web browser:</strong></p>\n<ol class="ak-ol" data-indent-level="1">\n<li>\n<p data-renderer-start-pos="329">Sign into your Roku account.</p>\n</li>\n<li>\n<p data-renderer-start-pos="361">Select the channel (YES Network) and click Cancel Subscription.</p>\n</li>\n</ol>\n<p data-renderer-start-pos="428"><strong>From channel navigation:</strong></p>\n<ol class="ak-ol" data-indent-level="1">\n<li>\n<p data-renderer-start-pos="456">On the remote control, find the home button and click on it.</p>\n</li>\n<li>\n<p data-renderer-start-pos="520">Go to the channel whose subscription you decided to terminate.</p>\n</li>'
  };
  const displayCancelSubscriptionInstructionBasedOnPlatform = () => {
    switch (platform) {
      case 'Android':
        return { __html: localization?.cancelSubscription?.cancel_instructions_android?.en || defaultInstructions.Android };
      case 'Roku':
        return { __html: localization?.cancelSubscription?.cancel_instructions_roku?.en || defaultInstructions.Roku };
      case 'Web':
        return { __html: localization?.cancelSubscription?.cancel_instructions_web?.en || defaultInstructions.Web };
      case 'Apple':
        return { __html: localization?.cancelSubscription?.cancel_instructions_apple?.en || defaultInstructions.Apple };
      case 'FireTV':
        return { __html: localization?.cancelSubscription?.cancel_instructions_firetv?.en || defaultInstructions.FireTV };
      case 'WebSplitit':
        return { __html: localization?.cancelSubscription?.cancel_instructions_web_splitit?.en || defaultInstructions.WebSplitit };
      default:
        break;
    }
  };


  return (
    <>
      <div onClick={handleFilterOpening}>
        {isOpen ? (
          <FontAwesomeIcon className="mr-2 minus-square" icon={faMinusSquare} />

        ) : (
          <FontAwesomeIcon className="mr-2 plus-square" icon={faPlusSquare} />
        )}
        {localization?.cancelSubscription?.cancel_subscription_title?.en ||
          'How can I cancel my subscription?'}
      </div>
      {isOpen && (
        <div dangerouslySetInnerHTML={displayCancelSubscriptionInstructionBasedOnPlatform()} />
      )}
    </>
  );

};

export default CancelSubscriptionInstruction;