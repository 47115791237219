import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import './ErrorScreen.scss';

const ErrorScreen = ({ title, content, vodList = false, hasButton, onClick }) => {
  const videoRef = useRef(null);
  const { remoteConfig, localization } = useSelector(state => state.app.watch);

  return (
    <div
      className={vodList ? 'error-screen-vodlist' : `error-screen ${hasButton && 'video-mode'}`}
    >
      {hasButton && (
        <video
          autoPlay
          muted
          id="error-screen-video"
          poster={remoteConfig?.dtcLaunchScreen?.fallbackURL}
          ref={videoRef}
          onEnded={() => {
            videoRef.current.play();
          }}
        >
          <source src={remoteConfig?.dtcLaunchScreen?.videoURL} type="video/mp4" />
        </video>
      )}
      <div className="error-screen_title">
        <h3 className={`title-width ${vodList ? 'tglb-b-db-33' : 'tglb-b-w-33'}`}>{title}</h3>
        <p className="mt-4 error-screen-subscribe-content">
          {remoteConfig?.isPhase1Enabled
            ? localization?.subscribeNewScreen?.contentRequiresSubscription.en || 'THE VIDEO YOU ARE TRYING TO WATCH REQUIRES A YES SUBSCRIPTION'
            : content}
        </p>
        {hasButton && (
          <button className="primary__button" onClick={onClick}>
            SUBSCRIBE NOW
          </button>
        )}
      </div>
    </div>
  );
};

export default ErrorScreen;
